<template>
  <b-modal
    id="add-promo-popup"
    :visible="visible"
    size="lg"
    ok-only
    ok-title="Сохранить"
    :title="title"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="mb-3">
        <div class="mb-3 d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="promo.is_published"
              switch
            />
            <div>
              Акция активна
            </div>
          </div>
          <div>
            <div class="flat-pickr-wrap">
              <flat-pickr
                v-model="promo.promo_ends_at"
                :config="datePickerConfig"
                placeholder="Дата окончания"
              />
              <small
                v-show="errors.has('promo_ends_at')"
                class="text-danger"
              >{{ errors.first('promo_ends_at') }}</small>
            </div>
          </div>
        </div>
        <div>
          <b-form-group
            label="Название акции"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="promo.title"
              v-validate="'required'"
              name="title"
            />
            <small
              v-show="errors.has('title')"
              class="text-danger"
            >{{ errors.first('title') }}</small>
          </b-form-group>
          <b-form-group
            label="Краткое описание акции"
            label-for="subtitle"
          >
            <b-form-input
              id="subtitle"
              v-model="promo.subtitle"
              name="subtitle"
            />
            <small
              v-show="errors.has('subtitle')"
              class="text-danger"
            >{{ errors.first('subtitle') }}</small>
          </b-form-group>
          <b-form-group
            label="URL (если оставить пустым, будет автоматически установлен)"
            label-for="slug"
          >
            <b-form-input
              id="slug"
              v-model="promo.slug"
              name="slug"
            />
            <small
              v-show="errors.has('slug')"
              class="text-danger"
            >{{ errors.first('slug') }}</small>
          </b-form-group>
          <div class="d-flex align-items-center my-2">
            <b-form-checkbox
              v-model="promo.only_direct_link"
              switch
            />
            <div>
              Только прямая ссылка
            </div>
          </div>
          <b-form-group
            label="Breadcrumb title"
            label-for="breadcrumb"
          >
            <b-form-input
              id="breadcrumb"
              v-model="promo.breadcrumb"
              name="breadcrumb"
            />
            <small
              v-show="errors.has('breadcrumb')"
              class="text-danger"
            >{{ errors.first('breadcrumb') }}</small>
          </b-form-group>
        </div>
      </div>
      <b-tabs
        pills
      >
        <b-tab active>
          <template #title>
            <span class="mr-1">
              <feather-icon
                icon="TagIcon"
              />
              Мета-теги
            </span>
          </template>
          <div class="py-2">
            <b-form-group
              label="Title"
              label-for="meta_title"
            >
              <b-form-input
                id="meta_title"
                v-model="promo.meta_title"
                name="meta_title"
              />
              <small
                v-show="errors.has('meta_title')"
                class="text-danger"
              >{{ errors.first('meta_title') }}</small>
            </b-form-group>
            <b-form-group
              label="Description"
              label-for="meta_description"
            >
              <b-form-textarea
                id="meta_description"
                v-model="promo.meta_description"
                name="meta_description"
                rows="4"
                no-resize
              />
              <small
                v-show="errors.has('meta_description')"
                class="text-danger"
              >{{ errors.first('meta_description') }}</small>
            </b-form-group>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="mr-1">
              <feather-icon
                icon="LayoutIcon"
              />
              Контент
            </span>
            <b-badge
              v-if="errors.has('cover_image_key') || errors.has('content_preview')"
              v-b-tooltip.hover.top="'Заполните поля'"
              variant="danger"
            >
              <feather-icon
                icon="AlertCircleIcon"
                class="m-0"
              />
            </b-badge>
          </template>
          <div class="py-2">
            <b-form-group
              label="Баннер акции"
            >
              <input-file
                v-model="promo.cover_image_key"
                v-validate="'required'"
                size="lg"
                name="cover_image_key"
              />
              <small
                v-show="errors.has('cover_image_key')"
                class="text-danger"
              >{{ errors.first('cover_image_key') }}</small>
            </b-form-group>
            <b-form-group
              label="Контент"
              label-for="content_template"
            >
              <CKEditor
                v-model="promo.content"
                v-validate="'required'"
                :config="editorConfig"
                name="content"
              />
              <small
                v-show="errors.has('content')"
                class="text-danger"
              >{{ errors.first('content') }}</small>
            </b-form-group>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="mr-1">
              <feather-icon
                icon="BoxIcon"
              />
              Привязка к страницам
            </span>
          </template>
          <div class="py-2">
            <b-form-group>
              <b-form-checkbox
                v-model="promo.is_shown_on_index_page"
              >
                Показывать на главной
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <attach-to-brands
                v-model="promo.attached_brands"
                @change="changeBrands"
                @delete="changeBrands"
              />
              <small
                v-show="errors.has('attached_brands')"
                class="text-danger"
              >{{ errors.first('attached_brands') }}</small>
            </b-form-group>
            <b-form-group>
              <attach-to-services
                v-model="promo.attached_services"
                @change="changeServices"
                @delete="changeServices"
              />
              <small
                v-show="errors.has('attached_services')"
                class="text-danger"
              >{{ errors.first('attached_services') }}</small>
            </b-form-group>
            <b-form-group>
              <attach-to-contacts
                v-model="promo.contacts"
                @change="changeContacts"
                @delete="changeContacts"
              />
              <small
                v-show="errors.has('contacts')"
                class="text-danger"
              >{{ errors.first('contacts') }}</small>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </form>
  </b-modal>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import {
  BTabs, BTab, BFormCheckbox, BFormGroup, BFormInput, BFormTextarea, BBadge, VBTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru'
import { mapGetters } from 'vuex'
import 'flatpickr/dist/flatpickr.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CKEditor from 'ckeditor4-vue'

import TranslitRusToLat from '@core/utils/TranslitRusToLat'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'

import InputFile from '@core/components/input/InputFile.vue'
import Promo from '@/api/http/models/promo/Promo'
import AttachToBrands from './components/AttachToBrands.vue'
import AttachToServices from './components/AttachToServices.vue'
import AttachToContacts from './components/AttachToContacts.vue'

export default {
  components: {
    BTabs,
    BTab,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BBadge,
    InputFile,
    CKEditor: CKEditor.component,
    flatPickr,
    AttachToBrands,
    AttachToServices,
    AttachToContacts,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [mixinErrorsParse],

  props: {
    title: {
      type: String,
      default: 'Добавление акции',
    },
  },

  data() {
    return {
      visible: false,
      promo: {
        slug: '',
        is_published: false,
        is_shown_on_index_page: false,
        only_direct_link: false,
        title: '',
        subtitle: '',
        meta_title: '',
        meta_description: '',
        breadcrumb: '',
        content_preview: '',
        content: '',
        editorConfig: {
          extraAllowedContent: 'div(*)',
        },
        cover_image_key: '',
        promo_ends_at: '',
        attached_brands: [],
        attached_services: [],
        contacts: [],
      },
      datePickerConfig: {
        wrap: true,
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        minDate: 'today',
        locale: Russian,
      },
    }
  },

  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },

  async created() {
    this.visible = true
  },

  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },

    async handleSubmit() {
      this.promo.slug = TranslitRusToLat(this.promo.slug)

      if (this.promo.title && !this.promo.slug) {
        this.promo.slug = TranslitRusToLat(this.promo.title)
      }

      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Promo.add(this.currentSite.slug, this.promo)

        if (response.status === 'success' && response.data) {
          this.$emit('added', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Акция добавлена',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('add-promo-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при добавлении акции',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }

        this.$nextTick(() => {
          this.$bvModal.hide('page-edit-popup')
        })
      }
    },

    changeBrands(brandSlug) {
      const findIndex = this.promo.attached_brands.findIndex(brand => brand === brandSlug)

      if (findIndex !== -1) {
        this.promo.attached_brands.splice(findIndex, 1)
      } else {
        this.promo.attached_brands.push(brandSlug)
      }
    },

    changeServices(serviceSlug) {
      const findIndex = this.promo.attached_services.findIndex(service => service === serviceSlug)

      if (findIndex !== -1) {
        this.promo.attached_services.splice(findIndex, 1)
      } else {
        this.promo.attached_services.push(serviceSlug)
      }
    },

    changeContacts(contactId) {
      const findIndex = this.promo.contacts.findIndex(contact => contact === contactId)

      if (findIndex !== -1) {
        this.promo.contacts.splice(findIndex, 1)
      } else {
        this.promo.contacts.push(contactId)
      }
    },
  },
}
</script>

<style lang="scss">
  .flat-pickr-wrap .input {
    background: #fff;
  }
</style>
