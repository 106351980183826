<template>
  <div>
    <div class="d-flex">
      <div
        v-if="value.length"
        class="mr-1 text-nowrap"
        style="padding-top: 5px"
      >
        Показывать для услуг:
      </div>
      <div class="d-flex flex-wrap">
        <ul
          v-for="(service, index) in value"
          :key="index"
          class="list-unstyled d-flex m-0"
        >
          <li class="mr-1 mb-1">
            <b-button
              size="sm"
              variant="outline-primary"
              class="d-flex align-items-center"
              @click.prevent="$emit('delete', service)"
            >
              {{ service }}
              <feather-icon
                icon="XIcon"
                size="15"
                class="ml-1"
              />
            </b-button>
          </li>
        </ul>
        <div>
          <b-button
            size="sm"
            variant="primary"
            class="d-flex align-items-center"
            @click.prevent="showAttachPopup = true"
          >
            Добавить услугу
            <feather-icon
              icon="PlusIcon"
              size="15"
              class="ml-1"
            />
          </b-button>
        </div>
      </div>
    </div>
    <attach-service-popup
      v-if="showAttachPopup"
      :current-services="value"
      @change="$emit('change', $event)"
      @hidden="showAttachPopup = false"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

import AttachServicePopup from './AttachServicePopup.vue'

export default {
  components: {
    BButton,
    AttachServicePopup,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showAttachPopup: false,
    }
  },
}
</script>
