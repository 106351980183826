<template>
  <div>
    <div class="d-flex">
      <div
        v-if="value.length"
        class="mr-1 text-nowrap"
        style="padding-top: 5px"
      >
        Показывать для контактов:
      </div>
      <div class="d-flex flex-wrap">
        <ul
          v-for="(contact, index) in selectedContacts"
          :key="index"
          class="list-unstyled d-flex m-0"
        >
          <li class="mr-1 mb-1">
            <b-button
              size="sm"
              variant="outline-primary"
              class="d-flex align-items-center"
              @click.prevent="$emit('delete', contact.id)"
            >
              {{ contact.city }}, {{ contact.address }}
              <feather-icon
                icon="XIcon"
                size="15"
                class="ml-1"
              />
            </b-button>
          </li>
        </ul>
        <div>
          <b-button
            size="sm"
            variant="primary"
            class="d-flex align-items-center"
            @click.prevent="showAttachPopup = true"
          >
            Добавить контакт
            <feather-icon
              icon="PlusIcon"
              size="15"
              class="ml-1"
            />
          </b-button>
        </div>
      </div>
    </div>
    <attach-contact-popup
      v-if="showAttachPopup"
      :current-contacts="value"
      :contacts="contacts"
      @change="$emit('change', $event)"
      @hidden="showAttachPopup = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BButton } from 'bootstrap-vue'
import Contact from '@/api/http/models/contact/Contact'
import AttachContactPopup from './AttachContactPopup.vue'

export default {
  components: {
    BButton,
    AttachContactPopup,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showAttachPopup: false,
      contacts: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    selectedContacts() {
      return this.contacts.filter(contact => this.value.includes(contact.id))
    },
  },
  async created() {
    const response = await Contact.getAll(this.currentSite.slug)
    this.contacts = response.data || []
  },
}
</script>
