<template>
  <b-modal
    id="attach-contact-popup"
    :visible="visible"
    size="md"
    ok-only
    ok-title="Добавить"
    :title="title"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group>
        <b-form-input
          v-model="search"
          placeholder="Поиск контакта"
        />
      </b-form-group>
      <div
        class="accordion"
        role="tablist"
      >
        <div
          v-for="(contact, index) in searchedContacts"
          :key="index"
          class="accordion__item"
        >
          <div class="accordion-label accordion-label--disable-arrow">
            <div class="accordion-label__content">
              <div class="row align-items-center w-100">
                <div class="col-12">
                  <div class="d-flex align-items-center">
                    <div
                      class="d-flex align-items-center mr-2"
                      @click.stop
                    >
                      <b-form-checkbox
                        :checked="currentContacts.includes(contact.id)"
                        @change="$emit('change', contact.id)"
                      />
                    </div>
                    {{ contact.city }} {{ contact.address }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { BFormGroup, BFormInput, BFormCheckbox } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  props: {
    title: {
      type: String,
      default: 'Показывать акцию на страницах брендов',
    },
    currentContacts: {
      type: Array,
      default: () => [],
    },
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      search: '',
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    searchedContacts() {
      return this.contacts.filter(contact => {
        const searchValue = this.search.toLowerCase().trim()
        const searchText = `${contact.city} ${contact.address}`
        return searchText.toLocaleLowerCase().indexOf(searchValue) !== -1
      })
    },
  },
  async created() {
    this.visible = true
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      this.$nextTick(() => {
        this.$bvModal.hide('attach-contact-popup')
      })
    },
  },
}
</script>
