<template>
  <b-card>
    <div class="row mb-5">
      <div class="col-12 col-md-3">
        <b-form-input
          id="search"
          v-model="search"
          name="search"
          placeholder="Поиск"
          @keyup.enter="onSearch"
          @input="onInputSearch"
        />
      </div>
      <div class="col-12 col-md-3 d-flex align-items-center">
        <b-form-checkbox
          v-model="is_published"
          @change="onSearch"
        >
          Показать опубликованные
        </b-form-checkbox>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <b-button
          class="d-flex align-items-center"
          variant="primary"
          @click.prevent="showAddPopup = true"
        >
          <feather-icon
            icon="PlusIcon"
            size="18"
            class="mr-1"
          />
          Добавить акцию
        </b-button>
      </div>
      <div class="col d-flex justify-content-end">
        <b-button
          class="d-inline-flex align-items-center"
          variant="primary"
          :to="{name: 'promos_sort'}"
        >
          <feather-icon
            icon="GridIcon"
            size="18"
            class="mr-1"
          />
          Сортировка
        </b-button>
      </div>
    </div>
    <div
      v-if="promos.length"
    >
      <promos-list
        :promos="promos"
        @updated="updatePromos"
        @deleted="deletePromo"
      />
      <b-pagination
        v-if="total / per_page > 1"
        v-model="page"
        :total-rows="total"
        :per-page="per_page"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        align="right"
        @input="onChangePage"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    <div v-else>
      Нет акций
    </div>
    <add-promo-popup
      v-if="showAddPopup"
      @added="addPromo"
      @hidden="showAddPopup = false"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BButton, BFormInput, BFormCheckbox, BPagination,
} from 'bootstrap-vue'

import Promo from '@/api/http/models/promo/Promo'

import AddPromoPopup from './AddPromoPopup.vue'
import PromosList from './PromosList.vue'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BFormCheckbox,
    BPagination,
    PromosList,
    AddPromoPopup,
  },
  data() {
    return {
      showAddPopup: false,
      promos: [],
      search: '',
      is_published: false,
      page: 1,
      per_page: 1,
      total: 1,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    this.page = this.$route.query.page || 1
    this.search = this.$route.query.search || ''
    this.is_published = this.$route.query.is_published || 0

    await this.fetchPromos()
  },
  methods: {
    async fetchPromos() {
      const query = {}

      if (this.page) {
        query.page = this.page
      }

      if (this.search) {
        query.search = this.search
      }

      if (this.is_published) {
        query.is_published = 1
      }

      const response = await Promo.getAll(this.currentSite.slug, query)

      this.promos = response.data || []

      this.per_page = response.meta.per_page || 1

      this.total = response.meta.total || 1
    },
    onInputSearch() {
      if (this.search === '') {
        this.onSearch()
      }
    },
    onSearch() {
      const query = {}

      if (this.search) {
        query.search = this.search
      }

      if (this.is_published) {
        query.is_published = 1
      }

      if (
        JSON.stringify(this.$route.query) !== JSON.stringify(query)
      ) {
        this.page = 1

        this.$router.replace({
          query: {
            ...query,
          },
        })

        this.fetchPromos()
      }
    },
    onChangePage() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          page: this.page,
        },
      })

      this.fetchPromos()
    },
    addPromo(promo) {
      this.promos.push(promo)
    },
    updatePromos(promo) {
      const index = this.promos.findIndex(cur => cur.slug === promo.slug)

      if (index !== -1) {
        this.promos.splice(index, 1, promo)
      }
    },
    deletePromo(promo) {
      const index = this.promos.findIndex(cur => cur.slug === promo.slug)

      if (index !== -1) {
        this.promos.splice(index, 1)
      }
    },
  },
}
</script>
