<template>
  <b-modal
    id="attach-brand-popup"
    :visible="visible"
    size="md"
    ok-only
    ok-title="Добавить"
    :title="title"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group>
        <b-form-input
          v-model="search"
          placeholder="Поиск бренда"
        />
      </b-form-group>
      <div
        class="accordion"
        role="tablist"
      >
        <div
          v-for="(brand, index) in searchedBrands"
          :key="index"
          class="accordion__item"
        >
          <div class="accordion-label accordion-label--disable-arrow">
            <div class="accordion-label__content">
              <div class="row align-items-center w-100">
                <div class="col-12">
                  <div class="d-flex align-items-center">
                    <div
                      class="d-flex align-items-center mr-2"
                      @click.stop
                    >
                      <b-form-checkbox
                        :checked="currentBrands.includes(brand.slug)"
                        @change="$emit('change', brand.slug)"
                      />
                    </div>
                    <div class="accordion-label__icon">
                      <img
                        v-if="brand.logo_sm"
                        :src="brand.logo_sm.url"
                        alt="логотип"
                      >
                      <div
                        v-else
                        class="accordion-label__icon-cover"
                      />
                    </div>
                    {{ brand.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { BFormGroup, BFormInput, BFormCheckbox } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import SiteBrand from '@/api/http/models/site/brand/SiteBrand'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  props: {
    title: {
      type: String,
      default: 'Показывать акцию на страницах брендов',
    },
    currentBrands: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      brands: [],
      search: '',
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    searchedBrands() {
      return this.brands.filter(brand => {
        const searchValue = this.search.toLowerCase().trim()
        const name = brand.name && brand.name.toLowerCase().indexOf(searchValue) !== -1
        const nameRus = brand.name_rus && brand.name_rus.toLowerCase().indexOf(searchValue) !== -1
        const slug = brand.slug && brand.slug.toLowerCase().indexOf(searchValue) !== -1
        return name || nameRus || slug
      })
    },
  },
  async created() {
    const response = await SiteBrand.getAll(this.currentSite.slug)

    if (response.data) {
      this.brands = response.data
    }

    this.visible = true
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      this.$nextTick(() => {
        this.$bvModal.hide('attach-brand-popup')
      })
    },
  },
}
</script>
